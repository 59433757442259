/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveABTests } from '../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { trackEvent } from '../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';

const useDynamicRankImpressionEvent = (categoryData) => {
    const [isImpressionFired, setIsImpressionFired] = useState(false);
    const [isExclusionImpFired, setIsExclusionImpFired] = useState(false);
    const dispatch = useDispatch();
    const activeABTests = useSelector(getActiveABTests);
    const isAltProductRankEnabled = useSelector(getFeatureFlag('is-alt-product-rank-enabled'));
    const abTestsEnabled = isAltProductRankEnabled && categoryData.content?.entries?.[0]?.is_alt_rank_enabled;

    useEffect(() => {
        if (!isImpressionFired && abTestsEnabled) {
            const modelInfo = activeABTests?.model_names?.find((model) => model.id === categoryData?.category?.id);
            const segmentName = activeABTests?.dynamic_rank_segment;
            if (segmentName && modelInfo) {
                const modelName = modelInfo?.name || '';

                dispatch(
                    trackEvent({
                        eventCategory: 'Dynamic Rank',
                        eventAction: 'Impression',
                        eventLabel: modelName ? `${segmentName} - ${modelName}` : segmentName,
                    }),
                );

                setIsImpressionFired(true);
            }
        }
    }, [categoryData]);

    // Exclusion experience
    useEffect(() => {
        if (!isExclusionImpFired) {
            const isCategoryExcluded = activeABTests?.Exclusion?.every((model) => model?.id !== categoryData?.category?.id);
            const segmentName = activeABTests?.dynamic_rank_segment_exclusion;

            if (isCategoryExcluded) {
                dispatch(
                    trackEvent({
                        eventCategory: 'Dynamic Rank',
                        eventAction: 'Impression',
                        eventLabel: segmentName,
                    }),
                );
            }
            setIsExclusionImpFired(true);
        }
    }, [categoryData]);
};

export default useDynamicRankImpressionEvent;
