/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

const priceRangeFilter = (facetsData, priceRangeData) => {
    const priceRanges = [];
    let newFacetsData = facetsData;
    // filter  selected data by user on collection page
    facetsData.forEach((value) => (
    // price range data from graphql api call
        priceRangeData.entries.forEach((check) => {
        // comparing facetsData and priceRangeData
            if (check.value === value) { // matching value is pushing into priceRange array
                priceRanges.push({  // creating new object and pushing data into it
                    to: check.to,
                    from: check.from,
                });

                // removing above matched value and reassign
                newFacetsData = newFacetsData.filter((item) => item !== value);
            }
        })
    ));
    return { priceRanges, newFacetsData };
};

export default priceRangeFilter;
