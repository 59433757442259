/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */
import React from 'react';
import { Helmet } from 'react-helmet';

const getPriceRange = (priceArr, hasSale) => {
    const range = [];
    const hiLow = [];
    priceArr.forEach((price) => {
        if (hasSale && (price.type === 'sale')) range.push(price.value);
        if (!hasSale) range.push(price.value);
    });
    const sorted = range.sort((a, b) => a - b);
    if (sorted.length > 1) {
        hiLow.push(sorted.shift(0));
        hiLow.push(sorted.pop());
    } else hiLow.push(sorted.shift(0));
    return hiLow;
};

const offersAggregate = (low, high) => ({
    '@type': 'AggregateOffer',
    lowPrice: low,
    highPrice: high,
    priceCurrency: 'USD',
});

const offersOffer = (price) => ({
    '@type': 'Offer',
    price,
    priceCurrency: 'USD',
});

const offers = (data) => {
    const hasSale = !!data.find(({ type }) => type === 'sale');
    const range = getPriceRange(data, hasSale);
    if (range.length > 1) return offersAggregate(range[0], range[1]);
    return  offersOffer(range[0]);
};

// function appends OG Carousel data to <head>
const seoOgCarousel = (data, brand) => {
    const COUNT = 6;
    const carousel = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [],
    };

    let indexCount = 1;
    for (let prod = 0; prod < COUNT; prod += 1) {
        if (!data[prod]?.name) break;
        const el = {
            '@type': 'ListItem',
            position: indexCount, // the for loop could skip a product,
            item: {
                '@type': 'Product',
                name: data[prod]?.name,
                image: [
                `https:${data[prod]?.image?.path}${data[prod]?.image?.name}x.jpg`,
                ],
                offers: offers(data[prod]?.prices),
                url: `https://www.${brand.domain}.com${data[prod]?.seo?.url}`,
            },
        };
        indexCount += 1;
        carousel.itemListElement.push(el);
    }
    const fullScript = <Helmet><script type="application/ld+json">{JSON.stringify(carousel)}</script></Helmet>;
    return fullScript;
};

export default seoOgCarousel;
