/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useEffect } from 'react';
import mbpUtil from 'mbp-api-util';
import mbpLogger from 'mbp-logger';
import useCompare from './useCompare';
import findProductPageByPartNumber from '../queries/findProductPageByPartNumber';
import findURL from '../queries/findURL';

/**
 * @param {object} featureFlag - LD flag, block query usage in React comp
 * @param {array} products - array of products to prefetch
 * @param {string} brand - flowers or cookies, you decide!
 */

const useSWPrefetchPDP = (featureFlags, products, brand) => {
    try {
        if (
            'serviceWorker' in navigator
            && 'postMessage' in navigator.serviceWorker.controller
            && featureFlags['is-prefetch-pdp-hook-enabled']
            && products.length > 0
            && brand
        ) {
            // used to create unique value to check for updates to products array with useCompare()
            const key = new Date().getTime();
            // in sw null is replaced with product info as it loops through the array of products
            const queryPartNumber = findProductPageByPartNumber(brand, null).loc.source.body;
            const queryFindURL = findURL(brand, null).loc.source.body;

            useEffect(() => {
                // if service worker is active, will return controller
                const { serviceWorker: { controller } } = navigator;
                // send message object to service worker
                controller.postMessage({
                    queryPartNumber,
                    queryFindURL,
                    WC_HOST: mbpUtil.getEnv('APP_WC_HOST'),
                    products,
                });
                // update on change of these values, only shallow comparison, so need something unique to diff array
            }, [useCompare(JSON.stringify(`${products[0].partNumber}${key}`)), featureFlags['is-prefetch-pdp-hook-enabled'], brand]);
        }
    } catch (ex) {
        // required prop errors
        if (!featureFlags && featureFlags['is-prefetch-pdp-hook-enabled'] === false) {
            mbpLogger.logError({
                message: `useSWPrefetchPDP.js Feature flag is-prefetch-pdp-hook-enabled is set to FALSE for ${brand} or featureFlags are undefined ${featureFlags} \n error = ${ex}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'useSWPrefetchPDP',
            });
        }
        if (products.length < 1) {
            mbpLogger.logError({
                message: `useSWPrefetchPDP.js products array is emtpy ${products} \n error = ${ex}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'useSWPrefetchPDP',
            });
        }
        if (!brand) {
            mbpLogger.logError({
                message: `useSWPrefetchPDP.js brand is undefined ${brand} \n error = ${ex}`,
                appName: process.env.npm_package_name,
                module: 'mbp-pwa-ui',
                function: 'useSWPrefetchPDP',
            });
        }
    }
};

export default useSWPrefetchPDP;
