/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import usePrevious from './usePrevious';

/**
 *
 * @param {function, boolean, string, number} val - used with usePrevious to comapare current and previous props
 */

const useCompare = (val) => {
    const prevVal = usePrevious(val);
    return prevVal !== val;
};

export default useCompare;
