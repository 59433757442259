/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { shape, string } from 'prop-types';

function GraphqlCollectionSeoSchema({ categoryData, productData: { pathWithoutCdn, imageName } }) {
    let pageTitle = '';
    let metaDescription = '';
    if (categoryData?.page_title && categoryData?.meta_description && pathWithoutCdn && imageName) {
        pageTitle = categoryData.page_title;
        metaDescription = categoryData.meta_description;
        return (
            <Helmet>
                <script type="application/ld+json">
                    {`{"@context":"http://schema.org","@type":"CollectionPage","name":"${pageTitle.split(' | ')[0]}","description":"${metaDescription}","primaryImageOfPage":{"@type":"ImageObject","url":"https://cdn1.${pathWithoutCdn}${imageName}x.jpg"},"image":{"@type":"ImageObject","url":"https://cdn1.${pathWithoutCdn}${imageName}x.jpg"}}`}
                </script>
            </Helmet>
        );
    }
    return null;
}

GraphqlCollectionSeoSchema.propTypes = {
    categoryData: shape({
        page_title: string.isRequired,
        meta_description: string.isRequired,
    }).isRequired,
    productData: shape({
        image: {
            name: string.isRequired,
        }.isRequired,
    }).isRequired,
};
export default GraphqlCollectionSeoSchema;
