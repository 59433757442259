/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import getDeviceType from './DesktopMobile/isDesktopMobileTablet';

const getProductLayout = (content) => {
    let productLayout = {};
    const deviceType = getDeviceType();

    if (!content || !content.entries || !content.entries.length) {
        return productLayout;
    }

    const [details] = content.entries;

    productLayout = details?.product_layout?.find((device) => {
        /**
         * cms configuration looks like
         * device.name = "Mobile"
         * device.name = "Desktop/Tablet"
         */

        const isMobile = device?.name?.toLowerCase().includes('mobile');

        if (deviceType === 'mobile' && isMobile) {
            return true;
        }

        // tablet falls under desktop in product_layout
        if ((deviceType === 'desktop' || deviceType === 'tablet') && !isMobile) {
            return true;
        }

        return false;
    });

    return productLayout;
};

export default getProductLayout;
